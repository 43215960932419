<script>
  import Can from "./Can.svelte";

  export let count = 0;

  let data = getData();
  let cans = [];
  let search = "";
  let color = "";
  let edition = "";
  let editions = [];

  async function getData() {
    const res = await fetch(`data.json`);
    const data = await res.json();

    if (res.ok) {
      let editionsSet = new Set();
      for (const can of data) {
        can.rgb = hexToRgb(`#${can.color}`);
        if (can.edition !== "") editionsSet.add(can.edition);
      }
      editions = [...editionsSet];
      cans = data;
      count = cans.length;
      return data;
    } else {
      throw new Error(data);
    }
  }

  function hexToRgb(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
      return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? [
          parseInt(result[1], 16),
          parseInt(result[2], 16),
          parseInt(result[3], 16),
        ]
      : null;
  }

  function distance(v1, v2) {
    let i = 0;
    let d = 0;
    for (i = 0; i < v1.length; i++) {
      d += (v1[i] - v2[i]) * (v1[i] - v2[i]);
    }
    return Math.sqrt(d);
  }

  function filterCans(cans) {
    let filtered = cans;

    if (search !== "" || edition !== "") {
      filtered = cans.filter((can) => {
        const isSearched =
          search === ""
            ? false
            : can.flavour.toLowerCase().includes(search.toLowerCase());
        const isEdition = edition === "" ? false : can.edition === edition;
        return isSearched || isEdition;
      });
    }

    if (color !== "") {
      let colorRgb = hexToRgb(color);
      filtered.sort((canA, canB) => {
        let distA = distance(colorRgb, canA.rgb);
        let distB = distance(colorRgb, canB.rgb);

        if (distA < distB) return -1;
        else if (distA > distB) return 1;
        return 0;
      });
    }
    return filtered;
  }

  $: filteredCans = search || color || edition !== "" ? filterCans(cans) : cans;
</script>

<div uk-grid class="uk-child-width-1-1">
  <div uk-grid>
    <div class="uk-width-1-1@s uk-width-expand@m">
      <form class="uk-search uk-search-default uk-width-1-1@s">
        <span uk-search-icon />
        <input
          class="uk-search-input"
          type="search"
          placeholder="Filter by flavour"
          bind:value={search}
        />
      </form>
    </div>
    <div class="uk-flex uk-flex-center uk-width-1-1@s uk-width-expand@m">
      <label for="color">Filter by edition</label>
      <select class="uk-select" bind:value={edition}>
        <option value="">All</option>
        {#each editions as editionItem}
          <option value={editionItem}>{editionItem}</option>
        {/each}
      </select>
    </div>
    <div class="uk-width-1-1@s uk-width-expand@m">
      <label for="edition">Sort by color</label>
      <input type="color" bind:value={color} />
    </div>
  </div>

  {#await data}
    <span uk-spinner />
  {:then cans}
    <div
      class="uk-grid-match uk-child-width-1-2@s uk-child-width-1-3@m
      uk-child-width-1-4@l"
      uk-grid
    >
      {#each filteredCans as can, i}
        <div>
          <div class="uk-card uk-card-default uk-card-hover">
            <Can {can} />
          </div>
        </div>
      {/each}
    </div>
  {:catch error}
    <div class="uk-alert-danger" uk-alert>
      <h3>There was a problem loading data.</h3>
      <p>{error.message}</p>
    </div>
  {/await}
</div>

<script>
  export let count = 0;
  let countAll = 162;
</script>

<div uk-grid class="uk-child-width-1-1">

  <div>
    <h2 id="about">About</h2>

    <h3>Why?</h3>

    <p>
      Somebody collect stamps, somebody hockey cards. I collect Pringles cans.
      Do I need reason to collect something? Maybe - I guess I just like the
      rainbow that different colors make.
    </p>

    <h3>How many cans?</h3>

    <p>
      According to
      <a href="https://www.snackhistory.com/pringles">snackhistory.com</a>
      there are at least {countAll} different flavours. Therefore at least {countAll}
      different cans that I can collect. So far I managed to get and taste {count}
      of them. Will I succeed and try them all? Who knows.
    </p>

    <h3>What info do you store?</h3>

    <p>
      Obviously image and flavour, which is searchable. Following by approximate
      hex color of a can. You can sort by color (usually quickest way to find
      out if I already own the can). Lastly there is country code (bought in)
      and taste points (range 1 - 10, disgusting - delicious).
    </p>

    <h3>Where do you get Pringles?</h3>

    <p>
      Most of them I obtained in candy/sweet stores which sells foreign
      products. Some can be bought in supermarkets where I live (Czech Republic)
      and some are brought by my friends from foreign countries.
    </p>

  </div>

  <div class="uk-margin">
    <div class="uk-margin uk-flex uk-flex-center">
      made with
      <span class="uk-margin-small-right uk-margin-small-left uk-text-danger">
        &#10084;
      </span>
      by&nbsp;
      <a href="http://hajnyon.cz" class="uk-link-muted">hajnyon</a>
    </div>

  </div>

</div>

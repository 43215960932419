<script>
  import About from "./About.svelte";
  import Cans from "./cans/Cans.svelte";
  import Nav from "./Nav.svelte";
  let count = 0;
</script>

<svelte:head>
  <link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/uikit/3.1.6/css/uikit.min.css" />
  <script
    src="https://cdnjs.cloudflare.com/ajax/libs/uikit/3.1.6/js/uikit.min.js">

  </script>
  <script
    src="https://cdnjs.cloudflare.com/ajax/libs/uikit/3.1.6/js/uikit-icons.min.js">

  </script>
</svelte:head>

<Nav {count} />

<div class="uk-container uk-margin-top">

  <Cans bind:count />

  <About {count} />

</div>

<script>
  export let can;
</script>

<style>
  .color {
    width: 18px;
    height: 18px;
    display: inline-block;
    border: 1px solid rgb(126, 126, 126);
  }
  .can-img {
    padding-top: 15px;
    width: 100px;
  }
</style>

<div class="uk-card-media-top uk-text-center">
  <img
    class="can-img"
    src={`/images/cans/${can.image}`}
    alt="Can color: #{can.color}" />
</div>
<div class="uk-card-body uk-text-center">
  <div class="uk-card-badge uk-label uk-label-warning">{can.edition}</div>
  <h3 class="uk-card-title">{can.flavour}</h3>
  <div class="uk-flex uk-flex-center uk-text-small">
    <span
      class="color uk-margin-small-right"
      style="background: #{can.color};" />
    <span class="uk-margin-right">#{can.color}</span>
    <span class="uk-text-uppercase uk-margin-right">
      {can.country || 'N/A'}
    </span>
    {can.taste || 'N/A'}
  </div>
</div>

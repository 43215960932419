<script>
  export let count = 0;
</script>

<nav class="uk-navbar-container" uk-navbar>

  <div class="uk-navbar-left">
    <div class="uk-navbar-item">
      <img width="100" src="images/pringles.png" alt="Pringles logo" />
    </div>
    <a class="uk-navbar-item uk-logo" href="http://pringles.hajnyon.cz">
      Cans collection - {count} and counting!
    </a>
  </div>

  <div class="uk-navbar-right">

    <ul class="uk-navbar-nav">
      <li>
        <a href="#about">About</a>
      </li>
    </ul>

  </div>

</nav>
